.about-container {
    margin: 60px auto;
    margin-bottom: 0px;
    width: 80%;
  }
  
  .about-content {
    background-color: #ffffff;
    padding: 5px;
  }
  
  .about-header h1 {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
  }
  
  .about-info {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .about-text {
    flex: 1;
    margin-right: 20px;
  }
  
  .about-text h2 {
    color: #555;
  }
 
 
  