*{
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  padding: 0;
}

.navbar{
  background-color: #3ad8e4;
  padding: 10px 0;
  position: fixed; /* Change from sticky to fixed */
  top: 0;
  width: 100%; /* Ensure it spans the entire viewport */
  z-index: 100; /* Ensure it's above other content */
}

.navbar-brand{
  font-size: 1.2rem !important;
  color: #565387 !important;
}
.nav-br{
  font-size: 1.2rem !important;
  color: #565387 !important;
}

a{
  font-size: 1.3rem;
  text-transform: capitalize;
}

.is-active{
  font-weight: bold;
  border-bottom: 1px solid #565387;
}

a:hover{
  color: #0275d8 !important;
}
.brand-name{
  color: #0275d8 !important;
}


/*     Header Section    */

#header{
  width: 100%;
  min-height: 80vh;
  height: auto;
  margin-top: 70px;

  @media (max-width: 767px) {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .header-title {
    font-size: 24px;
  }
  .header-subtitle {
    font-size: 16px;
  }
}

#header h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;

  @media (max-width: 767px) {
    font-size: 24px;
  }
}

#header .brand-name{
  color: #3498db;
}

#header h2{
  color: #484848;
  font-size: 24px;

  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.logo-top{
  height:60px;
  width: 300px;
  @media (max-width: 767px) {
    height:40px;
    width: 180px;
  }
}

.footer-logo{
  width: 96%;
  align-self: center;
}

.header-img{
  text-align: right;
}

#header .animated{
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  color: red; 
}
 

@keyframes up-down{
  0%{
    transform: translateY(10px);
  }
  100%{
    transform: translateY(-10px);
  }
}