.footer{
  background-color: #333;
  color: #fff;
  padding: 10px 0;
  text-align: center;
  bottom: 0;
  width: 100%;
}
  
.container {
  max-width: 960px;
  margin: 0 auto;
}
.footterp {
  margin-bottom: 0rem;
}
  